import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Spinner from "./components/UI/Spinner";
import store from "./redux/";
import Config from "./configure";
import "./index.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.scss";
import "./assets/fonts/feather/css/feather.css";
import "flag-icon-css/sass/flag-icon.scss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


window.tabId = Date.now().toString();

const App = lazy(() => import("./App"));

const app = (
    <Provider store={store}>
        <BrowserRouter basename={Config.basename}>
            <Suspense fallback={<Spinner />}>
                <App />
            </Suspense>
        </BrowserRouter>
    </Provider>
);

window.Sentry = Sentry;

Sentry.init({
    dsn: process.env.MIX_SENTRY_DSN_PUBLIC,
    environment: process.env.MIX_SENTRY_ENV || "development",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(app, document.getElementById("root"));
